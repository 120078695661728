import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function createTopic(params: {
  name: string
  active_from: string
}) {
  const { data } = await axios.post<ActionItemResult<Article>>(
    '/api/v1/topic/',
    params
  )

  return data
}
