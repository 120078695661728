import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { TopicInterest } from '@/types/topicInterest'

export default async function fetchTopicInterests({
  page,
  count,
  withItem,
  select,
  sort,
  filter,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<TopicInterest>> {
  const { data } = await axios.get('/api/v1/topic-interest/', {
    cancelToken,
    params: { page, count, with: withItem, select, sort, filter },
  })

  return data
}
