import { Role } from './role'

export interface User {
  email_verified?: boolean
  is_banned?: boolean
  c_time?: string
  password?: string

  ban_from: string
  ban_message: string
  ban_to: string
  birthday: string
  active_to: string
  city?: {
    id?: number
    name?: string
    name_gen?: string
    name_geo?: string
    region_code?: number
    vk_id?: number
  }
  description?: string
  email: string
  first_name: string
  gender?: string
  id: number
  last_name: string
  local_id: number
  logo?: {
    id: number
    url: string
  }
  pivot?: {
    user_id: number
  }
  relation?: number
  position?: string
  roles: Role[]
  projectAccessUsers: {
    [key: string]: { id: number; local_id: number; roles: Role[] }
  }
  soc_url_facebook?: string
  soc_url_instagram?: string
  soc_url_ok?: string
  soc_url_telegram?: string
  soc_url_twitter?: string
  soc_url_vk?: string
  soc_url_youtube?: string
  yandex_email?: string
  work_position?: string
  super_user_id: number | null
  settings?: {
    seoFieldsFontWeight: 'normal' | 'bold'
  }
}

export type UserDetailPreview = Omit<
  User,
  | 'id'
  | 'ban_from'
  | 'ban_to'
  | 'ban_message'
  | 'password'
  | 'projectAccessUsers'
>

export interface UserEditData {
  email?: string
  first_name?: string
  last_name?: string
  password?: string
  roles?: string[]
  email_verified?: boolean
  gender?: string | number
  relation?: number
  city?: string | number
  birthday?: string | number
  active_to?: string | number | null
  description?: string | number
  soc_url_facebook?: string | number
  soc_url_instagram?: string | number
  soc_url_vk?: string | number
  soc_url_twitter?: string | number
  soc_url_youtube?: string | number
  soc_url_telegram?: string | number
  yandex_email?: string | number
  work_position?: string | number
  ban_from?: string | number
  ban_message?: string | number
  ban_to?: string | number
  projectAccessUsers?: {
    [key: string]: { id: number; local_id: number; roles: Role[] }
  }
  settings?: {
    seoFieldsFontWeight: 'normal' | 'bold'
  }
}

export interface CommentAdminUser {
  email: string
  first_name: string
  last_name: string
  id: number
  local_id: number
}

export enum UserPermission {
  CREATE = 'create',
  EDIT = 'edit',
  READ = 'read',
  DELETE = 'delete',
  BAN = 'ban',
  PUBLISH_WITH_STOP_WORDS = 'publishWithStopWords',
  DELETE_NEVER_PUBLISHED = 'delete.neverPublished',
}

export enum UserPermissionPage {
  ARTICLE = 'article',
  MAILING = 'mailing',
  MAILING_INTEREST = 'mailingInterest',
  USER = 'user',
  HOME = 'home',
  CALENDAR_EVENTS = 'calendarEvents',
  PUBLICATION_PLAN = 'publicationPlan',
  RECIPE = 'recipe',
  RECIPE_PRODUCT = 'recipeProduct',
  RECIPE_DISH = 'recipeDish',
  RECIPE_INGREDIENT = 'recipeIngredient',
  MARKETING_SEO = 'marketingSeo',
  FEATURE = 'feature',
  SECTION = 'section',
  TAG = 'tag',
  AUTHOR = 'author',
  EXPERT = 'expert',
  WIDGET = 'widget',
  VIDEO = 'video',
  ISSUE = 'issue',
  COMMENT = 'comment',
  DESIGN_BLOCK = 'designBlock',
  POPBLOCK = 'popblock',
  DESIGN_FIXED = 'designFixed',
  DESIGN_CONTEST = 'designContest',
  POPUP = 'popup',
  ANALYTICS_SUMMARY = 'analyticsSummary',
  ANALYTICS_GENERAL = 'analyticsGeneral',
  ANALYTICS_CONTENT = 'analyticsContent',
  ANALYTICS_SEARCH_ENGINES = 'analyticsSearchEngines',
  DISTRIBUTION_PUSH = 'distributionPush',
  ADV_SWITCH = 'advSwitch',
  ADV_TAG = 'advTag',
  SETTINGS_CONTACTS = 'settingsContacts',
  SETTINGS_STATIC = 'settingsStatic',
  BLOCK = 'block',
  BRAND = 'brand',
  PODCAST = 'podcast',
  GALLERY = 'gallery',
  TOPIC = 'topic',
  TOPIC_INTEREST = 'topicInterest',
}

export enum UserPermissionArticleField {
  ACTIVE_TO = 'active_to',
  ACTIVE_FROM = 'active_from',
  NATIVE_ADVERTISING = 'native_advertising',
  ADVERTISING_GET_FIELD = 'extra_fields.advertisingGetField',
  ADVERTISING_DOMAIN = 'extra_fields.advertisingDomain',
  ADVERTISING_AGE = 'extra_fields.advertisingAge',
  MODERATION_STATUS = 'moderation_status',
}

export enum UserPermissionWidgetField {
  HTML = 'html',
}

export enum UserPermissionBlockField {
  ARTICLE_SCROLL = 'articleScroll',
}

export type UserPermissionField =
  | UserPermissionArticleField
  | UserPermissionWidgetField
  | UserPermissionBlockField

export type UserPermissions = Record<
  string,
  Record<
    UserPermissionPage,
    {
      fields?: Record<
        UserPermissionField,
        {
          permissions: UserPermission[]
        }
      >
      permissions?: UserPermission[]
    }
  >
>
