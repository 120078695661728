import { ActionItemResult } from '@/types/action'
import { TopicInterest } from '@/types/topicInterest'
import axios from 'axios'

export default async function updateTopicInterest({
  id,
  ...payload
}: Pick<TopicInterest, 'id'> & Partial<TopicInterest>): Promise<
  ActionItemResult<TopicInterest>
> {
  const { data } = await axios.put(`/api/v1/topic-interest/${id}/`, payload)
  return data
}
