import { ActionItemResult } from '@/types/action'
import { TopicInterest } from '@/types/topicInterest'
import axios, { CancelToken } from 'axios'

export default async function fetchTopicInterest({
  id,
  withItem,
  cancelToken,
  ...params
}: {
  id: number
  withItem?: string
  cancelToken?: CancelToken
}): Promise<ActionItemResult<TopicInterest>> {
  const { data } = await axios.get(`/api/v1/topic-interest/${id}`, {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })

  return data
}
