import axios from 'axios'
import { Article, ArticleItemMetaFields } from '@/types/article'
import { ActionItemResult } from '@/types/action'

export default async function fetchTopic({
  id,
  withItem,
}: {
  id: number
  withItem?: string
}) {
  const { data } = await axios.get<
    ActionItemResult<Article, ArticleItemMetaFields>
  >(`/api/v1/topic/${id}`, {
    params: {
      with: withItem,
    },
  })

  data.item.draft_id = data.item.draft_id || data.item.id
  data.item.issue = data.item.issue || null

  return data
}
