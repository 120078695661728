import { ActionItemResult } from '@/types/action'
import { TopicInterest } from '@/types/topicInterest'
import axios from 'axios'

export default async function createTopicInterest({
  name,
  code,
}: {
  name: string
  code: string
}): Promise<ActionItemResult<TopicInterest>> {
  const { data } = await axios.post('/api/v1/topic-interest/', { name, code })
  return data
}
