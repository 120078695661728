export enum Role {
  ADMIN = 'admin',
  CHIEF_EDITOR = 'chief-editor',
  EDITOR = 'editor',
  ADV_EDITOR = 'adv-editor',
  RECIPE_EDITOR = 'recipe-editor',
  MAILING_MANAGER = 'mailing-manager',
  SIRIUS_USER = 'sirius-user',
  YANDEX_METRICS_USER = 'yandex-metrika-user',
  VERSTKA_MANAGER = 'verstka-manager',
  BRAND_MANAGER = 'brand-manager',
  COMMENT_MANAGER = 'comment-manager',
  COMMUNITY_MANAGER = 'community-manager',
}
